import React from "react"
import ContactUsForm from "../components/Forms/ContactUsForm"
import SEO from "../components/utils/seo"
import { graphql } from "gatsby"
import { get } from "lodash"
import { MaxWidthInnerContainer } from "../styles/globalCss"
import { HeaderText, BodyText } from "../components/Partials/TextPieces"
import BenefitsList from "../components/Membership/BenefitsList"
import BackgroundImage from "../components/Partials/BackgroundImage"
import WideImage from "../components/Partials/WideImage"
import Carousel from "../components/Partials/Carousel/Carousel"
import Spacer from "../components/Partials/Spacer"

const Membership = ({ data }) => {
    const { fluid: bkgImg } = get(
        data,
        "contentfulMembershipPage.backgroundImage",
        {}
    )
    const { description: bkgImgDescription } = get(
        data,
        "contentfulMembershipPage.backgroundImage"
    )
    const { fluid: wideImg } = get(
        data,
        "contentfulMembershipPage.heroImage",
        {}
    )
    const { description: heroImgDescription } = get(
        data,
        "contentfulMembershipPage.heroImage"
    )
    const { mainHeader } = get(data, "contentfulMembershipPage")
    const pageDescription = get(
        data,
        "contentfulMembershipPage.pageDescription.childMarkdownRemark.html"
    )
    const { benefitsHeader } = get(data, "contentfulMembershipPage")
    const { benefits } = get(data, "contentfulMembershipPage", {})
    const { carouselHeader } = get(data, "contentfulMembershipPage.carousel")
    const carouselDescription = get(
        data,
        "contentfulMembershipPage.carousel.carouselDescription.childMarkdownRemark.html"
    )
    const carouselImages = get(
        data,
        "contentfulMembershipPage.carousel.images",
        []
    )

    return (
        <>
            <SEO title="Membership" />
            <BackgroundImage bkgImg={bkgImg} description={bkgImgDescription} />
            <WideImage img={wideImg} description={heroImgDescription} />
            <MaxWidthInnerContainer>
                <HeaderText copy={mainHeader} isH1 />
                <BodyText copy={pageDescription} padding />
                <Spacer size="medium" />
                <HeaderText copy={benefitsHeader} smallH2 />
                <BenefitsList benefits={benefits} />
                <Spacer size="medium" />
                <HeaderText copy={carouselHeader} smallH2 />
                <BodyText copy={carouselDescription} padding />
                <Spacer size="small" />
            </MaxWidthInnerContainer>
            <Carousel imgs={carouselImages} />
            <Spacer size="medium" />
            <ContactUsForm bkgImg={bkgImg} description={bkgImgDescription} />
        </>
    )
}

export default Membership

export const query = graphql`
    query {
        contentfulMembershipPage {
            backgroundImage {
                description
                fluid(maxWidth: 1440, quality: 75) {
                    ...GatsbyContentfulFluid
                }
            }
            benefits {
                benefit
                id
            }
            benefitsHeader
            carousel {
                carouselDescription {
                    childMarkdownRemark {
                        html
                    }
                }
                carouselHeader
                images {
                    hoverText
                    image {
                        description
                        fluid(maxWidth: 400, quality: 75) {
                            ...GatsbyContentfulFluid
                        }
                        id
                    }
                }
            }
            heroImage {
                description
                fluid(maxWidth: 1440, quality: 75) {
                    ...GatsbyContentfulFluid
                }
            }
            mainHeader
            pageDescription {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`
