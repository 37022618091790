import React from "react"
import styled from "@emotion/styled"
import { fontFamily } from "../../styles/fonts/fontFamily"
import { colors } from "../../styles/colors"
import { BreakLine } from "../../styles/globalCss"

const BenefitList = ({ benefits }) => {
    return benefits.map(({ id, benefit }) => (
        <React.Fragment key={id}>
            <P>{benefit.toUpperCase()}</P>
            <BreakLine />
        </React.Fragment>
    ))
}

export default BenefitList

const P = styled.p`
    font-family: ${fontFamily.content};
    color: ${colors.black};
    font-size: 16px;
    letter-spacing: 3.11px;
    padding: 2px 0;
    margin: 50px 0;
    display: inline-block;
`
